<!-- start page title -->
<app-pagetitle title="Bookings" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row mt-3">
  <div class="col-lg-6">
      <div class="card">
          <div class="card-body">
              <div id="circle_radialbar" class="apex-charts" dir="ltr">
                  <apx-chart [series]="customAngleChartOptions.series" [chart]="customAngleChartOptions.chart"
                      [plotOptions]="customAngleChartOptions.plotOptions" [labels]="customAngleChartOptions.labels"
                      [legend]="customAngleChartOptions.legend" [colors]="customAngleChartOptions.colors"
                      [responsive]="customAngleChartOptions.responsive"></apx-chart>
              </div>
          </div>
      </div>
      <!--end card-->
  </div><!-- end col -->
  <div class="col-lg-6">
      <div class="card">
          <div class="card-body">
            <div id="monochrome_pie_chart" class="apex-charts" dir="ltr">
              <apx-chart [series]="semiDonutChartOptions.series" [chart]="semiDonutChartOptions.chart"
                  [labels]="semiDonutChartOptions.labels"
                  [dataLabels]="semiDonutChartOptions.dataLabels" [legend]="semiDonutChartOptions.legend">
              </apx-chart>
            </div>
          </div>
      </div>
      <!--end card-->
  </div><!-- end col -->
</div><!-- end row -->

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body data-table-card">
        <div class="table-responsive mt-4 mt-sm-0 datatables">
          <table class="table align-middle table-nowrap table-check">
            <thead>
              <div class="search-container">
                <span class="form-booking-size form-booking-search">Search: </span>
                <input #inp type="text" id="search" class="form-control form-control-sm ms-2 form-input-bg form-booking-size" [(ngModel)]="searchTerm" (ngModelChange)="search()"  placeholder="Find by name">
              </div>
            <tr class="bg-transparent">
              <th style="width: 30px;">
                <div class="form-check font-size-16">
                  <input type="checkbox" [(ngModel)]="masterSelected" class="form-check-input" id="checkAll" (change)="checkUncheckAll()">
                  <label class="form-check-label" for="checkAll"></label>
                </div>
              </th>
              <th>Booking ID</th>
              <th class="sortable" (click)="sort({ column: 'created_at', direction: isDesc ? 'asc' : 'desc' })">Reservation</th>
              <th class="sortable" (click)="sort({ column: 'checkin_date', direction: isDesc ? 'asc' : 'desc' })">Arrival</th>
              <th>Nights</th>
              <th class="sortable" (click)="sort({ column: 'first_name', direction: isDesc ? 'asc' : 'desc' })">Guest name</th>
              <th>Guests</th>
              <th>Status</th>
              <th style="width: 120px;">Action</th>
            </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="bookings.length > 0; else noBookingsFound">
            <tr *ngFor="let booking of bookings; let index;">
              <td>
                <div class="form-check font-size-16">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="booking.isSelected" id="contacusercheck1">
                  <label class="form-check-label" for="contacusercheck1"></label>
                </div>
              </td>
              <td>{{ booking.id}}</td>
              <td>{{ booking.created_at | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
              <td>{{ booking.checkin_date | date: 'yyyy-MM-dd' }}</td>
              <td>{{ booking.nrOfNights }}</td>
              <td>{{ booking.first_name }} {{ booking.last_name }}</td>
              <td>{{ booking.nrOfGuests }}</td>
              <td style="text-transform: capitalize;">
                <i class="uil-check-circle" *ngIf="booking.status === 'cancelled'" style="color: red;"></i>
                <i class="uil-check-circle" *ngIf="booking.status === 'confirmed'" style="color: green;"></i>
                <i class="uil-check-circle" *ngIf="booking.status === 'pending'" style="color: rgb(228, 156, 12);"></i>
                {{ booking.status }}
              </td>
              <td>
                <ng-container *ngIf="booking.status === 'pending'; else viewButton">
                  <button  type="button" class="btn btn-primary submit_btn submit_border_radius" (click)="openbookingDetailsModal(showBookingDetailsModal, index)">
                  Confirm
                  </button>
                </ng-container>
                <ng-template #viewButton>
                  <button class="btn view-booking-btn" (click)="openbookingDetailsModal(showBookingDetailsModal, index)">View</button>
                </ng-template>
              </td>

            </tr>
          </ng-container>
            <ng-template #noBookingsFound>
              <tr>
                <td colspan="8" class="text-center no-bookings">No bookings found</td>
              </tr>
            </ng-template>
            </tbody>
          </table>
        </div>

        <!-- End table -->
        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
              Showing
              {{(bookings.length > 0 ? 1 : 0)}} to
              {{bookings.length}}
              of {{totalRecords}}
              entries
            </div>
          </div>

          <!-- Pagination -->
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end pagination-rounded">
              <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
              </ngb-pagination>
            </div>
          </div>
          <!-- End Pagination -->
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
<ng-template #showBookingDetailsModal let-modal>
  <div class="modal-dialog custom-modal-dialog-booking-details">
    <div class="modal-content custom-modal-content-booking-details">
      <div class="modal-header">
        <h5 *ngIf="bookingDetails.status == 'cancelled'" class="modal-title" id="myModalLabel">Booking cancelled</h5>
        <h5 *ngIf="bookingDetails.status == 'pending'" class="modal-title" id="myModalLabel">Booking request pending</h5>
        <h5 *ngIf="bookingDetails.status == 'confirmed'" class="modal-title" id="myModalLabel">Booking confirmed</h5>
        <h5 *ngIf="bookingDetails.status == 'failed'" class="modal-title" id="myModalLabel">Booking failed</h5>
        <button type="button" class="btn-close" aria-label="Close"
        (click)="closeBookingDetailsModal(); modal.close('Close click')">
        </button>
      </div>
      <div class="modal-body">
        <div id="booking-details-modal">
          <div class="row">
            <dt class="col-sm-4 booking-text-color">Guest</dt>
            <dt class="col-sm-8 booking-text-color">{{bookingDetails.salutation}} {{bookingDetails.first_name }} {{bookingDetails.last_name}}</dt>
            <dt class="col-sm-4"></dt>
            <dd class="col-sm-8 print-font-color">{{bookingDetails.phone_number}}</dd>
            <dt class="col-sm-4"></dt>
            <dd class="col-sm-8 print-font-color">{{bookingDetails.email}}</dd>
            <dt class="col-sm-4 fw-normal print-font-color">Address</dt>
            <dd class="col-sm-8 print-font-color">{{bookingDetails.street}} {{bookingDetails.number}}</dd>
            <dt class="col-sm-4"></dt>
            <dd class="col-sm-8 print-font-color">{{bookingDetails.postcode}} {{bookingDetails.city}}</dd>
            <dt class="col-sm-4 fw-normal print-font-color">HotelCard</dt>
            <dd class="col-sm-8 print-font-color">{{bookingDetails.card_number}}</dd>
      </div>
      <hr>
      <div class="row">
        <dt class="col-sm-4 booking-text-color">Room</dt>
        <dd class="col-sm-8 print-font-color">{{bookingDetails.room_name}}</dd>
        <dt class="col-sm-4"></dt>
        <dd class="col-sm-8 print-font-color">{{bookingDetails.adults}} Adults,{{bookingDetails.children}} Children,{{bookingDetails.infants}} Infants</dd>
        <dt class="col-sm-4 fw-normal print-font-color">Check-in</dt>
        <dd class="col-sm-8 d-flex print-font-color">{{bookingDetails.checkin_date}}</dd>
        <dt class="col-sm-4 fw-normal print-font-color">Check-out</dt>
        <dd class="col-sm-8 d-flex print-font-color">{{bookingDetails.checkout_date}}</dd>
      </div>
      <hr>
      <div class="row">
        <dt class="col-sm-8 booking-text-color">Price</dt>
          <table class="table-price">
            <tbody>
              <tr *ngFor="let item of groupPriceDetails(bookingDetails.priceDetails).samePriceDetails">
                <td class="print-font-color">
                  {{ item.nightValues[0] }} nights
                </td>
                <td class="text-center print-font-color">
                  <del>{{ item.originalPrice }} CHF</del> &nbsp; {{ item.reductionPercentage !== null ? item.reductionPercentage : 'N/A' }}%
                </td>
                
                <td class="update-price-list booking-price-det print-font-color">{{ item.updatedPrice }} CHF</td>
              </tr>
            </tbody>
          </table>
          <table *ngIf="groupPriceDetails(bookingDetails.priceDetails).differentPriceDetail.length > 0" class="table-price">
            <tbody>
              <tr *ngFor="let item of groupPriceDetails(bookingDetails.priceDetails).differentPriceDetail">
                <!-- <td>
                  {{ item.nightValues[1] }} nights
                </td> -->
                <td class="print-font-color">
                  {{ item.nightValues[0] === 1 ? item.nightValues[0] + ' night' : item.nightValues[1] + ' nights' }}
                </td>
                <td class="text-center position-relative reduced-price print-font-color">
                  <del>{{ item.originalPrice }} CHF</del> &nbsp; {{ item.reductionPercentage !== null ? item.reductionPercentage : 'N/A' }}%
                </td>
                <td class="update-price-list price-list-table price-list-booking print-font-color">{{ item.updatedPrice }} CHF</td>
              </tr>
            </tbody>
          </table>    
        <dd class="col-sm-3 print-font-color">Breakfast</dd>
        <dd class="col-sm-9 d-flex justify-content-end print-font-color">{{bookingDetails.wants_breakfast === 1 ? 'Included' : 'Not Included'}}</dd>
          <tr *ngFor="let artical of articlePrice; let index;">
            <div class="row city-tax print-font-color">
              <td class="col-sm-9 print-font-color">{{artical.label}}</td>
              <td class="col-sm-3 d-flex justify-content-end print-font-color">
                <dd class="d-flex justify-content-end city-price print-font-color">{{artical.price}}</dd>
              </td>
            </div>
          </tr>
      </div>
      <hr>
      <div class="row">
        <dt class="col-sm-3 fw-bold booking-text-color">Total</dt>
        <dd class="col-sm-9 d-flex justify-content-end fw-bold print-font-color">{{bookingDetails.price}} {{bookingDetails.currency}}</dd>
      </div>
      <div class="row" *ngIf="bookingDetails.cancelled_reason_description !== null || bookingDetails.comments !== null">
        <hr>
        <dt class="col-sm-6 fw-bold booking-text-color">Guest Comments</dt>
          <div *ngIf="bookingDetails.cancelled_reason_description !== null; else elseBlock">
            <p class="mat-subheading-2 mt-20 mb-20 print-font-color">{{bookingDetails.cancelled_reason_description}} by 
              <span class="print-font-color">{{bookingDetails.first_name}} {{bookingDetails.last_name}}</span>
            </p>
          </div>
        <ng-template #elseBlock>
            <p *ngIf="bookingDetails.cancelled_reason_description !== null" class="mat-subheading-2 mt-20 mb-20 print-font-color">
              This booking has been cancelled on {{bookingDetails.cancelled_reason_description}} by <strong class="print-font-color">{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
            </p>
            <p *ngIf="bookingDetails.comments !== null" class="mat-subheading-2 mt-20 mb-20 print-font-color">
              {{bookingDetails.comments}} by <strong class="print-font-color">{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
            </p>
            <p *ngIf="bookingDetails.hotel.auto_booking === 1" class="mat-subheading-2 mt-20 mb-20 print-font-color">
              This booking has already been automatically confirmed to the customer as you have the <strong class="print-font-color">Auto</strong> booking mode activated.
            </p>
            <p *ngIf="bookingDetails.hotel.channel_manager_id" class="mat-subheading-2 mt-20 mb-20 print-font-color">
              This booking has been confirmed automatically because your property is connected to Hotelcard via a channel management system.
            </p>
            <p *ngIf="bookingDetails.hotel.auto_booking === 0 && bookingDetails.confirm_key && bookingDetails.status === 'pending'" class="mat-subheading-2 mt-20 mb-20 hide-in-print print-font-color">
              Please confirm this booking by clicking on confirm button
              <span *ngIf="confirm_success" class="text-success">You have confirmed the booking successfully.</span>
            </p>
        </ng-template>
      </div>
      <p *ngIf="bookingDetails.hotel.auto_booking === null && bookingDetails.hotel.channel_manager_id === null" class="mat-subheading-2 mt-20 mb-20 print-font-color">
        Please confirm the booking made by <strong class="print-font-color">{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
        <a href='mailto:{{bookingDetails.email}}?subject=Booking%20confirmation%20from%20{{bookingDetails.room_name}}'>
        {{bookingDetails.email}}</a> within 24 hours. Thank you.
      </p>
      </div>
        <div>
          <span *ngIf="bookingDetails.status === 'pending'">
            <hr>
            <button  type="button" class="btn btn-primary submit_btn submit_border_radius" *ngIf="!confirm_success"
              data-bs-dismiss="modal"
              (click)="modal.close('Close click')"
              [class.spinner]="loading" [disabled]="loading" (click)="confirmBooking({hotel_id: bookingDetails.hotel_id, booking_id: bookingDetails.id, key: bookingDetails.confirm_key})">
              Confirm booking
            </button>
            <button type="button" class="btn btn-outline-primary ms-2 print-btn" (click)="print()" color="warn" title="Click to print the page">
              <span class="material-icons print-icon">print</span> &nbsp;
              <span class="print-text">Print</span>
            </button>
          </span>
          <span *ngIf="bookingDetails.status === 'confirmed'">
            <hr>
            <button type="button" class="btn btn-outline-primary print-btn" (click)="print()" color="warn">
              <span class="material-icons print-icon">print</span> &nbsp;
              <span class="print-text">Print</span>
            </button>
          </span>
          <span *ngIf="bookingDetails.status === 'cancelled'">
            <hr>
            <button type="button" class="btn btn-outline-primary print-btn" (click)="print()" color="warn">
              <span class="material-icons print-icon">print</span> &nbsp;
              <span class="print-text">Print</span>
            </button>
          </span>
        </div>
        <div class="row">
          <div *ngIf="bookingDetails.cancelDetails.cancelAlertLabel != ''">
            <hr>
            <dt *ngIf="bookingDetails.cancelDetails.cancelAlertLabel != ''" class="col-sm-6">Cancelation Details</dt>
            <div class="col-sm-9" *ngIf="bookingDetails.cancelDetails.cancelAlertLabel === 'The time of your cancellation is within the period for free cancellation. The hotel will not charge you any costs.'">
              <p class="text-nowrap">Free cancelation until {{ formattedDate }} </p>
            </div>
            <div class="col-sm-9" *ngIf="bookingDetails.cancelDetails.cancelAlertLabel !== 'The time of your cancellation is within the period for free cancellation. The hotel will not charge you any costs.'">
              <p class="button-booking-danger ms-2">This booking is non refundable. By canceling this booking, the hotel will charge the guest 1’254 CHF.</p>
            </div>
            <hr>
            <div *ngIf="bookingDetails.cancelDetails.showButton == true">
              <button type="button" class="btn btn-danger " (click)="cancelBookingRequest(cancelBookingModal)" color="warn">Cancel booking</button>
            </div>
            <hr>
          </div>
        </div>

      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelBookingModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title modal-cancel-title">Cancel Booking</h5>
        <button type="button" class="btn-close check-text-color closeCancelBtn" (click)="closeCancelBookingModal(); modal.close()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <dt class="col-sm-4 booking-text-color">Guest</dt>
          <dt class="col-sm-8 booking-text-color">{{ bookingDetails.salutation }} {{ bookingDetails.first_name }} {{ bookingDetails.last_name }}</dt>
          <dd class="col-sm-4 check-text-color check-text-size mt-2">Check-in</dd>
          <dd class="col-sm-8 check-text-color check-text-size mt-2">{{ bookingDetails.checkin_date }}</dd>
          <dd class="col-sm-4 check-text-color check-text-size">Check-out</dd>
          <dd class="col-sm-8 check-text-color check-text-size">{{ bookingDetails.checkout_date }}</dd>
          <div *ngIf="bookingDetails.cancelDetails.cancelAlertLabel === 'The time of your cancellation is within the period for free cancellation. The hotel will not charge you any costs.'">
            <div class="d-flex">
              <svg xmlns="http://www.w3.org/2000/svg" class="mt-1" width="20" height="14" viewBox="0 0 20 14" fill="none">
                <path d="M19.1024 0.410092C19.6325 0.956882 19.6325 1.84487 19.1024 2.39166L8.24651 13.5899C7.71644 14.1367 6.8556 14.1367 6.32552 13.5899L0.897556 7.99078C0.367481 7.44399 0.367481 6.55601 0.897556 6.00922C1.42763 5.46243 2.28847 5.46243 2.81855 6.00922L7.28814 10.6154L17.1857 0.410092C17.7158 -0.136697 18.5766 -0.136697 19.1067 0.410092H19.1024Z" fill="#035A55"/>
              </svg>
              <p class="button-booking-success ms-2">Free cancelation until {{ formattedDate }} </p>
            </div>
          </div>
          <div *ngIf="bookingDetails.cancelDetails.cancelAlertLabel !== 'The time of your cancellation is within the period for free cancellation. The hotel will not charge you any costs.'">
            <div class="d-flex">
              <svg xmlns="http://www.w3.org/2000/svg" class="mt-1" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M11.9993 3C12.554 3 13.0658 3.30134 13.347 3.79554L21.7851 18.5812C22.0703 19.0795 22.0703 19.6942 21.7929 20.1924C21.5156 20.6906 20.996 21 20.4374 21H3.56117C3.00254 21 2.48297 20.6906 2.2056 20.1924C1.92824 19.6942 1.93215 19.0754 2.21342 18.5812L10.6515 3.79554C10.9328 3.30134 11.4445 3 11.9993 3ZM11.9993 8.14286C11.4797 8.14286 11.0617 8.57277 11.0617 9.10714V13.6071C11.0617 14.1415 11.4797 14.5714 11.9993 14.5714C12.5188 14.5714 12.9368 14.1415 12.9368 13.6071V9.10714C12.9368 8.57277 12.5188 8.14286 11.9993 8.14286ZM13.2494 17.1429C13.2494 16.8019 13.1177 16.4748 12.8832 16.2337C12.6488 15.9926 12.3308 15.8571 11.9993 15.8571C11.6677 15.8571 11.3498 15.9926 11.1153 16.2337C10.8809 16.4748 10.7492 16.8019 10.7492 17.1429C10.7492 17.4838 10.8809 17.8109 11.1153 18.052C11.3498 18.2931 11.6677 18.4286 11.9993 18.4286C12.3308 18.4286 12.6488 18.2931 12.8832 18.052C13.1177 17.8109 13.2494 17.4838 13.2494 17.1429Z" fill="#F74D4D"/>
              </svg>
              <p class="button-booking-danger ms-2">This booking is non refundable. By canceling this booking, the hotel will charge the guest 1’254 CHF.</p>
            </div>
          </div>
          <p class="check-text-color">Do you really want to cancel this booking? It can’t be restored.</p>
        </div>
      </div>
      <div class="modal-footer border-top">
        <button type="button" class="btn btn-secondary" (click)="closeCancelBookingModal(); modal.close()">Close</button>
        <button type="button" class="btn btn-danger" (click)="confirmCancelBooking()">Cancel Booking</button>
      </div>
</ng-template>