import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeatureService } from '../../core/services/feature.service';

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public selectAllStatus: { [category: string]: boolean } = {};
  public noAmenities: boolean = false;
  success:boolean = false;
  error: string = '';

  // Mock hotel data for demonstration
  public hotelData: any = {
    hotel_features: [
      {
        category: 'Food & Drinks',
        amenities: [
          { amenity: 'Breakfast', is_active: true },
          { amenity: 'Breakfast buffet', is_active: false },
          { amenity: 'Restaurant', is_active: false },
          { amenity: 'Bar', is_active: false },
          { amenity: 'Special diet menus (on request)', is_active: false },
          { amenity: 'Organically produced food', is_active: false }
        ],
      },
      {
        category: 'Rooms',
        amenities: [
          { amenity: 'Family room', is_active: false },
          { amenity: 'Soundproof rooms', is_active: false },
          { amenity: 'Hotel safe', is_active: false },
          { amenity: 'Ironing service', is_active: false },
          { amenity: 'Nespresso coffee machine', is_active: false },
          { amenity: 'Water kettle', is_active: false },
          { amenity: 'Air condition', is_active: false },
          { amenity: 'Allergy-free rooms', is_active: false },
          { amenity: 'Bath tub', is_active: false },
          { amenity: 'Barrier-free shower', is_active: false },
        ],
      },
      {
        category: 'Service',
        amenities: [
          { amenity: 'Reception 24h', is_active: false },
          { amenity: 'Self check-in', is_active: false },
          { amenity: 'Self check-out', is_active: false },
          { amenity: 'Ski storage', is_active: false },
          { amenity: 'Luggage storage', is_active: false },
          { amenity: 'Newspaper', is_active: false },
          { amenity: 'Laundry service', is_active: false },
          { amenity: 'Dry cleaning', is_active: false },
          { amenity: 'Bicycle rental', is_active: false },
          { amenity: 'E-bike rental', is_active: false },
          { amenity: 'Money exchange', is_active: false },
          { amenity: 'Room service', is_active: false },
          { amenity: 'Nursery', is_active: false },
          { amenity: 'Car rental', is_active: false },
          { amenity: 'Concierge service', is_active: false },
          { amenity: 'Airport shuttle', is_active: false }
        ],
      },
      {
        category: 'Public',
        amenities: [
          { amenity: 'Lift', is_active: false },
          { amenity: 'ATM', is_active: false },
          { amenity: 'Conference/event rooms', is_active: false },
          { amenity: 'Banquet hall', is_active: false },
          { amenity: 'Casino', is_active: false },
          { amenity: 'Beauty salon/barber', is_active: false },
          { amenity: 'Souvenirs', is_active: false },
          { amenity: 'Business center', is_active: false },
          { amenity: 'Chapel', is_active: false },
          { amenity: 'Drying room', is_active: false }
        ],
      },
      {
        category: 'Wellness & Spa',
        amenities: [
          { amenity: 'Outdoor pool', is_active: false },
          { amenity: 'Indoor pool', is_active: false },
          { amenity: 'Sauna', is_active: false },
          { amenity: 'Steam bath', is_active: false },
          { amenity: 'Relaxation room', is_active: false },
          { amenity: 'Infusions', is_active: false },
          { amenity: 'Rest area', is_active: false },
          { amenity: 'Massages', is_active: false },
          { amenity: 'Cosmetic treatments', is_active: false }
        ],
      },
      {
        category: 'General',
        amenities: [
          { amenity: 'Internet', is_active: false },
          { amenity: 'Free Wi-fi', is_active: false },
          { amenity: 'Charging station (Electromobility)', is_active: false },
          { amenity: 'Wheelchair-accessible infrastructure', is_active: false },
          { amenity: 'Disabled infrastructure', is_active: false },
          { amenity: 'Non smoking hotel', is_active: false },
          { amenity: 'Pets allowed', is_active: false },
          { amenity: 'Bikers are welcome', is_active: false },
          { amenity: 'Shelter/garage for motorcycles and bicycles', is_active: false },
          { amenity: 'Ecological hotel', is_active: false }
        ],
      },
    ]
  };

  constructor(private featureService: FeatureService) {}
  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.loadSavedAmenities();
  }
  

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Amenities', active: true }
    ];
  }

  subscribeToSelectedHotel(): void {
    const hotel = this.hotelData;
    if (hotel) {
      this.hotel = hotel;
    }
  }

  // Method to toggle the "Select All" checkbox for a specific category
  toggleSelectAll(category: any): void {
    const newStatus = !this.selectAllStatus[category.category];
    category.amenities.forEach((amenity: any) => amenity.is_active = newStatus);
    this.selectAllStatus[category.category] = newStatus;
  }

  // Check if "Select All" should be checked for a specific category
  selectAll(category: any): boolean {
    return category.amenities.every((amenity: any) => amenity.is_active);
  }

  // Toggles the is_active property of a specific amenity
  toggleAmenityStatus(amenity: any, category: any): void {
    amenity.is_active = !amenity.is_active;
    this.updateSelectAllStatus(category);
  }

  // Update the "Select All" checkbox status based on individual checkboxes
  updateSelectAllStatus(category: any): void {
    this.selectAllStatus[category.category] = category.amenities.every((amenity: any) => amenity.is_active);
  }

  // Method to remove an amenity from the list by object reference
  removeAmenity(amenity: any, category: any): void {
    const index = category.amenities.indexOf(amenity);
    if (index > -1) {
      category.amenities.splice(index, 1);
    }

    // Check if there are no amenities left in the category
    if (category.amenities.length === 0) {
      this.noAmenities = true;
    } else {
      this.noAmenities = false;
    }

    this.updateSelectAllStatus(category);
  }

  saveAmenities(): void {
    console.log('Saving amenities...');
  
    const selectedAmenities = this.getSelectedAmenities();
  
    if (selectedAmenities.length > 0) {
      // Save the selected amenities in localStorage
      localStorage.setItem('selectedAmenities', JSON.stringify(selectedAmenities));
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 3000);
    } else {
      this.error = 'Please select at least one amenity.';
      setTimeout(() => {
        this.error = '';
      }, 3000);
    }
  }
  
  loadSavedAmenities(): void {
    const savedAmenities = localStorage.getItem('selectedAmenities');
    if (savedAmenities) {
      const amenities = JSON.parse(savedAmenities);
      this.hotel.hotel_features.forEach((category: any) => {
        category.amenities.forEach((amenity: any) => {
          const savedAmenity = amenities.find((a: any) => a.amenity === amenity.amenity);
          if (savedAmenity) {
            amenity.is_active = savedAmenity.is_active;
          }
        });
      });
    }
  }
  

  onSubmit(form: NgForm): void {
    if (form.valid) {
      const selectedAmenities = this.getSelectedAmenities();
      console.log('Form submitted with the following amenities:', selectedAmenities);
      form.resetForm();
    } else {
      console.log('Form is invalid');
    }
  }

  // Get all selected amenities from all categories
  getSelectedAmenities(): any[] {
    let selectedAmenities:any = [];
    this.hotel.hotel_features.forEach((category: any) => {
      selectedAmenities = selectedAmenities.concat(category.amenities.filter((amenity: any) => amenity.is_active));
    });
    return selectedAmenities;
  }
}
