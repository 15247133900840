import { Component, OnInit, ViewChildren, QueryList, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Booking } from '../../core/interfaces/booking';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { BookingResponse } from '../../core/interfaces/booking-response';
import {SortEvent} from "../../core/interfaces/sort-event";
import {BookingListSortableDirective} from '../../core/directives/booking-sorting.directive';
import {setupCustomAngleChart, setupSemiDonutChart} from '../../core/interfaces/booking-chart-data';
import { BookingStatsService } from '../../core/services/booking-stats.service';
import { tap, catchError, map } from 'rxjs/operators';
import { Availability } from '../../core/interfaces/availability';
import moment from 'moment'
// Define the PriceDetail interface here
interface PriceDetail {
  originalPrice: number;
  reductionPercentage: number | null;
  updatedPrice: number;
  nightValues: number[]
}
export interface BookingDetails {
  priceDetails: { [key: string]: PriceDetail }; // Define the type for priceDetails
  // Add other necessary properties
}
@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  providers: [DecimalPipe]
})
export class BookingsComponent implements OnInit, OnDestroy {
  @ViewChildren(BookingListSortableDirective) headers!: QueryList<BookingListSortableDirective>;
  @ViewChild('cancelBookingModal', { static: false }) cancelBookingModal!: TemplateRef<any>;
  bookings: Booking[] = [];
  selectedHotel: Hotel | null = null;
  private subscriptions: Subscription = new Subscription();
  public hotel$: Observable<Hotel | null>;
  hotelId: any;
  masterSelected: boolean;
  checkedList: any;
  breadCrumbItems!: Array<{}>;
  totalBookings: number = 0;
  isDesc: boolean = false;
  column: string = 'created_at';
  page: number = 1;
  pageSize: number = 25;
  totalRecords: number = 0;
  customAngleChartOptions: any;
  semiDonutChartOptions: any;
  bookingDetails: any;
  loading = false;
  confirm_success = false;
  searchTerm: string = '';
  roomNightPrice: any;
  roomNightPriceList: { roomId: string; nights: number }[] = [];
  private nightsList: number[] = [];
  articlePrice: any;
  // Flags to manage modal visibility
  isBookingDetailsModalOpen: boolean = false;
  isCancelBookingModalOpen: boolean = false;
  formattedDate: string = '';

  constructor(
    private modalService: NgbModal,
    private hotelService: HotelService,
    private bookingStatsService: BookingStatsService
  ) {
    this.hotel$ = this.hotelService.selectedHotel$;
    this.masterSelected = false;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
        this.selectedHotel = hotel;
        if (hotel) {
          this.loadBookings();
          /**
          * Chart Data Fetch Function
          */
          this._fetchData();
        } else {
          this.bookings = [];
        }
      })
    );

    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Bookings', active: true }
    ];
  }

  loadBookings(): void {
    if (this.selectedHotel) {
      const params = {
        page: this.page,
        per_page: this.pageSize,
        sort: this.isDesc ? `-${this.column}` : this.column,
        locale: 'en'
      };
      this.hotelService?.getSelectedHotelBookings(params).subscribe((response: BookingResponse | null) => {
        if (response) {
          this.bookings = response?.data?.map(booking => {
            const checkinDate = new Date(booking?.checkin_date);
            const checkoutDate = new Date(booking?.checkout_date);
            const timeDiff = Math.abs(checkoutDate.getTime() - checkinDate.getTime());
            const nrOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
            const nrOfGuests = booking?.adults + booking?.children + booking?.infants;

            return {
              ...booking,
              nrOfNights,
              nrOfGuests
            };
          });
          this.totalRecords = response?.total;
        }
      });
    }
  }

  sort({ column, direction }: SortEvent): void {
    this.isDesc = direction === 'desc';
    this.column = column;
    this.loadBookings();
  }

  openModal(content: any): void {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  checkUncheckAll(): void {
    for (let i = 0; i < this.bookings.length; i++) {
      this.bookings[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected(): void {
    this.masterSelected = this.bookings.every(item => item.isSelected);
    this.getCheckedItemList();
  }

  getCheckedItemList(): void {
    this.checkedList = [];
    for (let i = 0; i < this.bookings.length; i++) {
      if (this.bookings[i].isSelected) {
        this.checkedList.push(this.bookings[i]);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onPageChange(page: number): void {
    this.page = page;
    this.loadBookings();
  }
/**
 * Chart Data Fetch
 */
public _fetchData() {
  if (this.selectedHotel) {
    const params = {
      locale: 'en'
    };

    this.hotelService.getSelectedHotelBookings(params).subscribe(
      (response: BookingResponse | null) => {
        if (response) {
          this.hotel$.subscribe({
            next: (hotelData) => {
              const percentages: number[] = [
                hotelData?.totalNumberOfNights.roomsNightsCount.reduced || 0,
                hotelData?.totalNumberOfNights.roomsNightsCount.reduced_30 || 0,
                hotelData?.totalNumberOfNights.roomsNightsCount.reduced_10 || 0
              ];
              const customAngleLabels = ['50% Room Nights', '30% Room Nights', '10% Room Nights'];
              this.customAngleChartOptions = setupCustomAngleChart(percentages, customAngleLabels, this.bookingStatsService);

              const semiDonutSeries = [hotelData?.bookings || 0, hotelData?.cancelledBookings || 0];
              const semiDonutLabels = ['Total Bookings', 'Cancelled Bookings'];
              const semiDonutColors = ['#038edc', '#51d28c', '#f7cc53', '#f34e4e'];
              this.semiDonutChartOptions = setupSemiDonutChart(semiDonutSeries, semiDonutColors, semiDonutLabels);

            },
            error: (err) => {
              console.error('Error fetching hotel data:', err);
            }
          });

        }
      },
      (error) => {
        console.error('Error fetching hotel bookings:', error);
      }
    );
  }
}
/**
 * search the booking records
 */
search() {
  this.hotel$.subscribe({
    next: (hotelData) => {
       this.hotelId = hotelData?.id;
    }
  });
  const filters = {
    page: this.page,
    per_page: this.pageSize,
    sort: this.isDesc ? `-${this.column}` : this.column,
    locale: 'en',
    total: 0,
    loaded_component: true,
    loading_data: true,
    search: this.searchTerm,
    search_context: ''
  };
  return this.bookingStatsService.getHotelAll(this.hotelId, filters).subscribe((response: BookingResponse | null) => {
    if (response) {
      this.bookings = response?.data?.map(booking => {
        const checkinDate = new Date(booking?.checkin_date);
        const checkoutDate = new Date(booking?.checkout_date);
        const timeDiff = Math.abs(checkoutDate.getTime() - checkinDate.getTime());
        const nrOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const nrOfGuests = booking?.adults + booking?.children + booking?.infants;

        return {
          ...booking,
          nrOfNights,
          nrOfGuests
        };
      });
      this.totalRecords = response?.total;
    }
  });
}
/**
 * Booking details modal
*/

formatCardNumber(cardNumber: any): string {
  // Check if cardNumber is a string or can be converted to a string
  if (typeof cardNumber !== 'string') {
    cardNumber = String(cardNumber); // Convert to string if it's not
  }

  // Remove any non-digit characters
  const cleaned = cardNumber.replace(/\D/g, '');

  // Match the cleaned number with the desired pattern
  const match = cleaned.match(/(\d{3})(\d{3})(\d{3})/);

  // If the number doesn't match the pattern, return it as is
  if (!match) return cardNumber;

  // Format as 'xxx-xxx-xxx'
  return `${match[1]}-${match[2]}-${match[3]}`;
}


openbookingDetailsModal(showBookingDetailsModal:any, booking: any, confirm_success = false): void {
  if (confirm_success) {
    booking.confirm_success = true;
  }
  this.bookingStatsService.getBooking(booking.id).subscribe({
    next: (response: any) => {
      this.roomNightPrice = response.data.bookingPrices.roomNightPrice;
      const nightValues = this.prepareRoomNightPriceList();
      this.articlePrice = response.data.bookingPrices.articlePrice;
      // Format the card number
      booking.card_number = this.formatCardNumber(booking.card_number);
      if (response.data.salutation == 'f' ) {
        this.bookingDetails.salutation = 'Ms.';
      } else {
        this.bookingDetails.salutation = 'Mr.';
      }
      // Assuming bookingDetails.checkout_date is in ISO 8601 format or any format that Moment.js can parse
      const checkoutDate = moment(this.bookingDetails.checkout_date);
            
      // Format the date as "11 September 2025, 3:00 PM"
      this.formattedDate = checkoutDate.format('DD MMMM YYYY, h:mm A'); 
      // Prepare to store prices and reduction percentages
      const availabilities = this.bookingDetails.booking_data.availabilities;
      const priceDetails: { [key: string]: PriceDetail } = {};

      for (const [date, details] of Object.entries(availabilities)) {
        const availabilityDetails = details as Availability;

        // Store original price and reduction percentage
        const originalPrice = availabilityDetails.price;
        let reductionPercentage: number | null = null;
        const reduction = availabilityDetails.availability;

        // Determine reduction percentage based on response
        if (reduction === 'reduced') {
          reductionPercentage = 50; // 50% reduction
        } else if (reduction.startsWith('reduced_')) {
          reductionPercentage = parseInt(reduction.split('_')[1], 10); // e.g., 10 or 30
        }

        // Calculate updated price
        const updatedPrice = reductionPercentage !== null
          ? parseFloat((originalPrice * (1 - (reductionPercentage / 100))).toFixed(2))
          : parseFloat(originalPrice.toFixed(2));

        priceDetails[date] = {
          originalPrice: originalPrice,
          reductionPercentage: reductionPercentage,
          updatedPrice: updatedPrice,
          nightValues
        };
      }

      // Store price details in booking details
      this.bookingDetails.priceDetails = priceDetails;
      console.log("Total",this.bookingDetails);
      const conversionRate = 1.07; // 1 CHF = 0.94 EUR (example rate)

if (this.bookingDetails.currency === 'EUR') {
  this.bookingDetails.price = (this.bookingDetails.price * conversionRate).toFixed(2); // Convert and format
  this.bookingDetails.currency = 'EUR';
} else {
    this.bookingDetails.currency = 'CHF';
}


    }
  });

  this.bookingDetails = booking;
  this.modalService.open(showBookingDetailsModal);
}
private prepareRoomNightPriceList(): number[] {
  if (this.roomNightPrice) {
    this.roomNightPriceList = Object.keys(this.roomNightPrice).map(key => ({
      roomId: key,
      nights: this.roomNightPrice![key] // Access the value (number of nights)
    }));

    // Store the nights in the class property
    this.nightsList = this.roomNightPriceList.map(room => room.nights);
    console.log(this.nightsList);

    // Return the nightsList
    return this.nightsList;
  }

  // Return an empty array if roomNightPrice is not defined
  this.nightsList = []; // Reset nightsList
  return [];
}

groupPriceDetails(priceDetails: { [key: string]: PriceDetail }): {
  samePriceDetails: Array<PriceDetail>,
  differentPriceDetail: Array<PriceDetail>
} {
  // Prepare the room night price list
  const nightValues = this.prepareRoomNightPriceList();

  const grouped = new Map<string, {
    originalPrice: number,
    reductionPercentage: number,
    updatedPrice: number,
    dates: string[],
    nightValues: number[]
  }>();

  for (const [date, details] of Object.entries(priceDetails)) {
    const key = `${details.originalPrice}-${details.reductionPercentage}-${details.updatedPrice}`;

    if (!grouped.has(key)) {
      grouped.set(key, {
        originalPrice: details.originalPrice,
        reductionPercentage: details.reductionPercentage !== null ? details.reductionPercentage : 0,
        updatedPrice: details.updatedPrice,
        dates: [],
        nightValues // Use the nightsList from the prepareRoomNightPriceList
      });
    }

    const group = grouped.get(key);
    if (group) {
      group.dates.push(date);
    }
  }

  // Separate into two arrays
  const samePriceDetails: PriceDetail[] = [];
  const differentPriceDetail: PriceDetail[] = [];

  grouped.forEach((value) => {
    if (value.dates.length > 1) {
      samePriceDetails.push(value);
    } else {
      differentPriceDetail.push(value);
    }
  });

  return { samePriceDetails, differentPriceDetail };
}






confirmBooking(bookingData: { hotel_id: number, booking_id: number, key: string }) {
  this.loading = true; // Show loading spinner
  this.confirm_success = false; // Reset success flag
  this.bookingStatsService.confirmBooking(bookingData).subscribe({
    next: (response: any) => {
      // Handle the success response here
      this.loading = false;
      this.confirm_success = true;
      // Optionally update booking status locally
      this.bookingDetails.status = 'confirmed';
      // Optionally close the modal or give user feedback
      this.modalService.dismissAll();
    },
    error: (error: any) => {
      // Handle error response here
      this.loading = false;
      this.confirm_success = false;
      console.error('Error confirming booking:', error);
    }
  });
}
// print() {
//   window.print();
// }
print(): void {
  // Get screen width and height
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  // Open a temporary container div to measure the content height
  const tempContainer = document.createElement('div');
  tempContainer.style.visibility = 'hidden';
  tempContainer.style.position = 'absolute';
  tempContainer.style.height = 'auto';
  tempContainer.innerHTML = this.generatePrintContent(1,1); // Generate content for measurement
  document.body.appendChild(tempContainer);

  // Get the height of the content dynamically
  const contentHeight = tempContainer.offsetHeight;
  
  // Remove the temporary container
  document.body.removeChild(tempContainer);

  // Define the print window's width (keep this fixed) and calculate the printable area
  const printWindowWidth = 800;  // You can adjust this based on the actual print width
  const pageHeight = 1120; // Adjust this according to your target page size (e.g., A4 ~ 1120px)

  // Calculate the total pages required for the content
  const totalPages = Math.ceil(contentHeight / pageHeight);

  // Calculate the position to center the window
  const left = (screenWidth - printWindowWidth) / 2;
  const top = (screenHeight - contentHeight) / 2;

  // Open a new print window with the dynamically calculated height
  const printWindow = window.open('', '', `width=${printWindowWidth},height=${contentHeight},left=${left},top=${top}`);

  // Write the content to the print window
  printWindow?.document.write(this.generatePrintContent(1,totalPages));

  // Close the document to ensure the content is loaded
  printWindow?.document.close();

  // Force reflow by accessing an element or triggering a small timeout
  setTimeout(() => {
    // Trigger the print dialog
    printWindow?.print();
  }, 500);  // Wait 500ms to allow styles to apply
}


generatePrintContent(currentPage: number, totalPages: number): string {
  // Access the modal content by its ID
  const modalContent = document.getElementById('booking-details-modal')?.innerHTML || '';
  
  // Function to generate the styles for the page (inlined for print)
  const getStyles = () => {
    const styles = document.querySelectorAll('link[rel="stylesheet"], style');
    let stylesHtml = '';
    styles.forEach((style) => {
      if (style.tagName.toLowerCase() === 'link') {
        stylesHtml += `<link rel="stylesheet" type="text/css" href="${(style as HTMLLinkElement).href}">`;
      } else if (style.tagName.toLowerCase() === 'style') {
        stylesHtml += `<style>${style.innerHTML}</style>`;
      }
    });
    return stylesHtml;
  };

  // Function to inject inline styles for print and modal content
  const inlineStyles = () => {
    const modalElement = document.getElementById('booking-details-modal');
    if (modalElement) {
      const computedStyles = window.getComputedStyle(modalElement);
      let styleRules = '';
      for (let i = 0; i < computedStyles.length; i++) {
        const property = computedStyles[i];
        const value = computedStyles.getPropertyValue(property);
        styleRules += `${property}: ${value}; `;
      }
      return styleRules ? `#booking-details-modal { ${styleRules} }` : '';
    }
    return '';
  };

  // Determine the status message based on the bookingDetails.status
  let statusMessage = '';
  switch (this.bookingDetails.status) {
    case 'cancelled':
      statusMessage = 'Booking cancelled';
      break;
    case 'pending':
      statusMessage = 'Booking request pending';
      break;
    case 'confirmed':
      statusMessage = 'Booking confirmed';
      break;
    case 'failed':
      statusMessage = 'Booking failed';
      break;
    default:
      statusMessage = 'Booking Details'; // Default to "Booking Details" if no match
  }

  // Function to format the date as DD.MM.YYYY
  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // Get current date in the desired format
  const currentDate = formatDate(new Date());

  // Combine everything together for the print page
  return `
    <html>
      <head>
        <title>Booking Details</title>
        <!-- Injecting necessary styles -->
        ${getStyles()}
        <style>
          /* General styles */
          html, body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            -webkit-print-color-adjust: exact; /* For WebKit-based browsers (Chrome, Safari) */
            print-color-adjust: exact; /* Standard syntax for other browsers */
            overflow-x: hidden;
          }
          td.update-price-list.price-list-table.price-list-booking {
            left: -20px !important;
            display: flex;
            justify-content: end;
          }

          /* Inline styles specifically for printing */
          @media print {
            #booking-details-modal {
              ${inlineStyles()}
            }
          }
          html,body {
            overflow-x: hidden;
          }
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            -webkit-print-color-adjust: exact; /* For WebKit-based browsers (Chrome, Safari) */
            print-color-adjust: exact; /* Standard syntax for other browsers */
          }
          .logo {
            position: absolute;
            top: -24px;
            left: 15px;
            z-index: 1;
          }
          .wrapper-print {
            z-index: 10;
            position: relative;
          }
          .booking-details {
            margin-top: 100px;
          }
          .date {
            text-align: right;
            position: fixed;
            bottom: 0;
            right: 15px;
          }
          .content {
            margin-top: 30px;
            padding: 20px;
          }
          @page {
            margin: 20mm;
          }
          .page-footer {
            text-align: center;
            width: 100%;
            position: fixed;
            bottom: 0;
            z-index: 1;
          }
          .page-number {
            display: inline-block;
          }
          .bg-title-booking {
            background-color: #F0F2F8;
            padding: 10px 30px;
            font-size: 1.3em;
            position: relative;
            width: 100vw;
            margin-bottom: 15px;
            left: -30px;
          }
          .booking-price-det {
            left: -20px !important;
            display: flex;
            justify-content: end;
          }
          .city-tax {
            position: relative;
            left: 12px;
            margin-bottom: -24px;  
          }
          .breakfast-inc {
            position: relative;
            left: -115px;
          }
          .city-price {
            position: relative;
            top: -20px;
            left: 15px;
          }
          .hide-in-print {
            display: none !important;
          }
          .print-font-color {
            color: #777 !important;
          }
        </style>
      </head>
      <body>
        <div class="wrapper-print">
          <img src="../../../assets/images/logo.svg" alt="Hotel Logo" class="logo" width="150">
          <div class="content">
            <h2 class="modal-title bg-title-booking">${statusMessage}</h2>
            ${modalContent}
            <div class="date">${currentDate}</div>
            <div class="page-footer">
              <script type="text/javascript">
                window.onload = function () {
                  var pages = document.querySelectorAll(".page-footer");
                  if (pages.length > 0) {
                    pages[0].innerHTML = "Page " + (window.pageNum || 1) + " / " + ${totalPages};
                  }
                }
              </script>
              <p>Page <span class="page-number">${currentPage}</span> / <span class="total-pages">${totalPages}</span></p>
            </div>
          </div>
        </div>
      </body>
    </html>
  `;
}

confirmCancelBooking() {
  this.bookingStatsService.cancelBooking(this.bookingDetails).subscribe({
    next: (response:any) => {
      this.bookingDetails.status = 'cancelled';
      // Optionally close the modal or give user feedback
      this.modalService.dismissAll();
    },
    error: (error: any) => {
      console.error(error.data);
    }
  });
}

cancelBookingRequest(booking: any) {
  this.bookingStatsService.getBooking(booking.id).subscribe({
    next: (response: any) => {
      this.roomNightPrice = response.data.bookingPrices.roomNightPrice;
      const nightValues = this.prepareRoomNightPriceList();
      this.articlePrice = response.data.bookingPrices.articlePrice;
      // Format the card number
      booking.card_number = this.formatCardNumber(booking.card_number);
      if (response.data.salutation == 'f' ) {
        this.bookingDetails.salutation = 'Ms.';
      } else {
        this.bookingDetails.salutation = 'Mr.';
      }
      // Prepare to store prices and reduction percentages
      const availabilities = this.bookingDetails.booking_data.availabilities;
      const priceDetails: { [key: string]: PriceDetail } = {};

      for (const [date, details] of Object.entries(availabilities)) {
        const availabilityDetails = details as Availability;

        // Store original price and reduction percentage
        const originalPrice = availabilityDetails.price;
        let reductionPercentage: number | null = null;
        const reduction = availabilityDetails.availability;

        // Determine reduction percentage based on response
        if (reduction === 'reduced') {
          reductionPercentage = 50; // 50% reduction
        } else if (reduction.startsWith('reduced_')) {
          reductionPercentage = parseInt(reduction.split('_')[1], 10); // e.g., 10 or 30
        }

        // Calculate updated price
        const updatedPrice = reductionPercentage !== null
          ? parseFloat((originalPrice * (1 - (reductionPercentage / 100))).toFixed(2))
          : parseFloat(originalPrice.toFixed(2));

        priceDetails[date] = {
          originalPrice: originalPrice,
          reductionPercentage: reductionPercentage,
          updatedPrice: updatedPrice,
          nightValues
        };
      }

      // Store price details in booking details
      this.bookingDetails.priceDetails = priceDetails;
      console.log("Total",this.bookingDetails.price);
      const conversionRate = 1.07; // 1 CHF = 0.94 EUR (example rate)

if (this.bookingDetails.currency === 'EUR') {
  this.bookingDetails.price = (this.bookingDetails.price * conversionRate).toFixed(2); // Convert and format
  this.bookingDetails.currency = 'EUR';
} else {
    this.bookingDetails.currency = 'CHF';
}
      
    }
  });
  // Open the modal
  const modalRef = this.modalService.open(this.cancelBookingModal, {
    windowClass: 'cancelModalOpen'
  });
  modalRef.result.then(
    (result) => {
      // Handle modal close
      console.log('Modal closed with:', result);
    },
    (reason) => {
      // Handle modal dismiss
      console.log('Modal dismissed with:', reason);
    }
  );
}
// Function to close the modal
closeModal() {
  this.modalService.dismissAll();
}
// Close the booking details modal
closeBookingDetailsModal() {
  this.isBookingDetailsModalOpen = false;
}
// Close the cancel booking modal
closeCancelBookingModal() {
  this.isCancelBookingModalOpen = false;
}
}

