<div class="container-fluid mt-3">
    <!-- start page title -->
    <div class="d-flex justify-content-between align-items-center">
        <app-pagetitle title="Edit Room"></app-pagetitle>
        <!-- Breadcrumb navigation positioned to the right -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-end mb-0">
            <li *ngFor="let item of breadCrumbItems; let last = last" class="breadcrumb-item" [ngClass]="{ 'active': item.active }">
              <ng-container *ngIf="!last">
                <!-- Non-active breadcrumb items with routerLink -->
                <a [routerLink]="item.link">{{ item.label }}</a>
              </ng-container>
              <ng-container *ngIf="last">
                <!-- Active breadcrumb (the last item) with no link -->
                {{ item.label }}
              </ng-container>
            </li>
          </ol>
        </nav>
      </div>
    <!-- end page title -->
    <button type="submit" class="btn btn-primary submit_btn position-absolute add-room-btn cursor-pointer add-rooms-edit">Add room &nbsp;<i class="fas fa-plus fa-sm"></i>
    </button>
    <div class="card mt-5">
        <div class="card-body">
            <form (ngSubmit)="onSubmit()" #roomForm="ngForm">
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room Description</label>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex mb-2">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Duetsch</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.de }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description"
                                        placeholder="Room description"
                                        required> {{ room.description.de }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Francais</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.fr }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                            name="description"
                                            placeholder="Room description"
                                            required>{{ room.description.fr }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">English</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.en }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description"
                                        placeholder="Room description"
                                        required> {{ room.description.en }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Italiano</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.it }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description"
                                        placeholder="Room description"
                                        required> {{ room.description.it }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div>
                    <div class="col-2">
                        <button type="button" class="btn btn-link p-0">
                            <i class="fas fa-trash-alt delete-icon-danger"></i> <!-- Red delete icon -->
                        </button>
                        <img src="assets/images/drag.png" alt="drag-icon" class="img-fluid ms-2">
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room Size</label>
                    <div class="col-10">
                        <input type="email" class="form-control form-input-bg text-room-desc text-room-size" id="formrow-email-input" value="{{ room.average_room_size }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m²" required>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-2">
                      <h4 class="card-title">Amenities</h4>
                    </div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-auto">
                          <div class="form-check text-room-desc form-room-check">
                            <input 
                              class="form-check-input cursor-pointer" 
                              type="checkbox" 
                              [id]="'checkAll'"
                              (change)="toggleSelectAll()"
                              [checked]="selectAll" 
                              [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                              style="display: none;">
                            <label 
                              class="form-check-label cursor-pointer categories" 
                              [for]="'checkAll'"
                              [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                            >
                              Select all
                            </label>
                          </div>
                        </div>
                        <div class="col-auto" *ngFor="let features of room.features; let index;">
                            <div class="form-check text-room-desc form-room-check">
                                <!-- Dynamic ID for each checkbox input using index -->
                                <input 
                                    class="form-check-input cursor-pointer" 
                                    type="checkbox" 
                                    [id]="'check' + index"
                                    [checked]="features.is_active"
                                    (change)="updateSelectAllStatus()"
                                    [ngClass]="{'active': features.is_active, 'inactive': !features.is_active}"
                                    style="display: none;">
                                
                                <!-- Label with dynamic 'for' attribute matching the checkbox ID -->
                                <label 
                                    class="form-check-label cursor-pointer categories" 
                                    [ngClass]="{'active': features.is_active, 'inactive': !features.is_active}" 
                                    [for]="'check' + index">  <!-- Dynamic 'for' to match the checkbox ID -->
                                    {{ features.name }}
                                  <!-- Close svg for removing the features -->
                                  <svg *ngIf="features.is_active" xmlns="http://www.w3.org/2000/svg" class="btn-pills-close position: relative" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    (click)="removeAmenity(index)">
                                    <g id="close-outline 1">
                                    <path id="Vector" d="M11.5 11.5L4.5 4.5M11.5 4.5L4.5 11.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                  </svg>
                                </label>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Availability</label>
                    <div class="col-10">
                        <span class="text-room-color">Rooms per day on HotelCard</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg availability-desc cursor-pointer" type="number" value="{{room.amount}}" id="example-number-input">
                            <span class="form-check text-room-desc ms-5">
                                <input class="form-check-input cursor-pointer" type="checkbox" id="autoSizingCheckHide" [checked]="room.hide">
                                <label class="form-check-label cursor-pointer" for="autoSizingCheckHide">
                                    Hide room
                                </label>
                            </span>
                        </span>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Occupancy</label>
                    <div class="col-10">
                        <span class="text-room-color">Max no of adults</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg occupancy-desc cursor-pointer" type="number" value="{{room.max_people_allowed}}" id="example-number-input">
                            <div class="form-check text-room-desc ms-5">
                                <input class="form-check-input cursor-pointer" type="checkbox" id="autoSizingCheckFamily" [checked]="room.is_family_room">
                                <label class="form-check-label cursor-pointer" for="autoSizingCheckFamily">
                                    Family Room
                                </label>
                            </div>
                        </span>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Extra Beds</label>
                    <div class="col-10">
                        <select class="form-select extra-bed form-input-bg text-room-desc cursor-pointer">
                            <option>Select the bed</option>
                            <option>1</option>
                            <option>2</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Base Price</label>
                    <div class="col-10">
                        <input type="email" class="form-control form-input-bg text-room-desc text-room-size" id="formrow-email-input" value="{{room.base_price}}" required>
                        <div class="form-check form-switch form-switch-md mb-2 mt-1 text-room-desc" dir="ltr">
                            <input type="checkbox" class="form-check-input cursor-pointer" id="customSwitchsizemd" [checked]="room.is_breakfast_included">
                            <label class="form-check-label cursor-pointer label-switch-text position-relative" for="customSwitchsizemd">Breakfast included</label>
                        </div>
                        <div class="form-check text-room-desc">
                            <input class="form-check-input cursor-pointer" type="checkbox" id="formCheck1" [checked]="room.allow_single_use">
                            <label class="form-check-label cursor-pointer" for="formCheck1">
                                Single use
                            </label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room images</label>
                    <div class="col-10">
                        <div>
                            <dropzone class="dropzone"></dropzone>
                        </div>
                    </div>
                </div>
                <div class="form-check form-switch form-switch-md mb-2 mt-1 text-room-desc position-absolute hide-room-switch" dir="ltr">
                    <label class="form-check-label mt-1 cursor-pointer label-switch-text position-relative" for="customHideSwitchsizemd">Hide room</label>
                    <input type="checkbox" class="form-check-input position-relative form-hide-switch cursor-pointer" id="customHideSwitchsizemd" [checked]="room.hide">
                </div>
                <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer">Update room details</button>

            </form>
        </div><!-- end card-body -->
    </div><!-- end card -->
  </div>
