import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.scss'
})
export class MessagesComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public hotelMessages: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public i:number = 0;
  messages: any[] = [];
  languages = [
    { key: 1, code: 'de', name: 'Deutsch' },
    { key: 2, code: 'en', name: 'English' },
    { key: 3, code: 'fr', name: 'Français' },
    { key: 4, code: 'it', name: 'Italiano' }
    // Add more languages as needed
  ];
  success:boolean = false;
  deleteMessages:boolean = false;
  permanentMessagesCount = 0;
  selectedLanguage = this.languages[0].code; // Default to the first language

  constructor( private hotelService: HotelService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    // Load messages from localStorage when the component is initialized
    const storedMessages = localStorage.getItem('messages');
    if (storedMessages) {
      this.messages = JSON.parse(storedMessages);
      // After loading messages, call checkPermanentMessage()
      this.checkPermanentMessage();
    }
  }
  // Method to add a new message
  addMessage(): void {
    if (this.permanentMessagesCount >= 1) {
      // If a permanent message already exists, automatically set the new one as temporary
      const newMessage = this.createMessage('temporary');
      this.messages.push(newMessage);
    } else {
      // If no permanent message exists, set the new one as permanent
      const newMessage = this.createMessage('permanent');
      this.messages.push(newMessage);
    }
    this.saveMessages();
    this.checkPermanentMessage();
  }
  createMessage(displayType: 'permanent' | 'temporary'): any {
    return {
      deutsch: '',
      deutschDescription: '',
      french: '',
      frenchDescription: '',
      english: '',
      englishDescription: '',
      italian: '',
      italianDescription: '',
      display: displayType,
      isVisible: true
    };
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Messages', active: true}
    ];
  }
  selectLanguage(languageCode: string) {
    this.selectedLanguage = languageCode;
  }
  // Method to delete a message
  deleteMessage(index: number): void {
    this.messages.splice(index, 1);
    this.saveMessages();
    this.deleteMessages = true;
    this.checkPermanentMessage();
  }

  // Check if the permanent message condition is met
  checkPermanentMessage() {
    this.permanentMessagesCount = this.messages.filter(m => m.display === 'permanent').length;
  }

  // Method to save messages to localStorage
  saveMessages(): void {
  localStorage.setItem('messages', JSON.stringify(this.messages));
  // this.success = true;
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
      }
    });
  }
  onSubmit(form: NgForm) {
    // this.success = true;
    // this.router.navigate(['/']);
  }
}


