import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(
    private http: HttpService,
  ) { }

  getFeatures() {
    return this.http.get(`features`);
  }

  saveFeatures(activities: any, hotelId: number, id: number) {
    return this.http.put(`hotelsV2/features/${hotelId}/${id}`, activities).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel bookings', error);
        return of(null);
      })
    );
  }
}
