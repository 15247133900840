import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { NgForm } from '@angular/forms';
import { FeatureService } from '../../core/services/feature.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss'
})
export class ActivitiesComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public hotelActivities: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public i:number = 0;
  selectAll: boolean = false; // Variable to manage the "Select all" checkbox
  noActivities: boolean = false;  // Flag to track whether there are no activities
  public activities: Array<any> = [];
  success:boolean = false;
  error: string = '';

  constructor( private hotelService: HotelService,
    private featureService: FeatureService
  ) { }

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.subscribeToActivities();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Activities', active: true}
    ];
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.hotelActivities = [];
        this.hotel.hotel_features.forEach((hotelfeature: any) => {
          if (hotelfeature.feature_group_id === 2 && hotelfeature.is_active) {
            this.hotelActivities.push(hotelfeature);
          }
        });
      }
    });
    this.fetchActivities(); // Fetch the activities only when hotel data is available
  }

  fetchActivities(): void {
    this.featureService.getFeatures().subscribe((activities: any) => {
      this.activities = activities.data.activities;
  
      // Sync the fetched activities with the hotel activities
      this.activities.forEach(activity => {
        const hotelActivity = this.hotelActivities.find(hotelFeature => hotelFeature.id === activity.id);
        if (hotelActivity) {
          activity.is_active = hotelActivity.is_active;
        }
      });
  
      // Check if all activities are selected
      this.updateSelectAllStatus();
    });
  }

  // Check if all amenities are selected
  checkAllActivitiesStatus() {
    this.selectAll = this.activities.every(activity => activity.is_active);
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const selectedActivities = this.activities.filter(activity => activity.is_active).map(choice => ([choice.id]));
      const result = {
          "features": selectedActivities.map(String)
      };
      this.featureService.saveFeatures(result, this.hotel.id, 2).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          console.log("Success");
        },
        (error) => {
          this.error = 'There was an error saving the Aactivities. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
          console.error("Failed", error);
        }
      );
      console.log('Form submitted with the following activities:', result);
      form.resetForm();
    } else {
      console.log('Form is invalid');
    }
  }

  // Method to toggle the "Select All" checkbox
  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.activities.forEach(activity => activity.is_active = newStatus);
    this.selectAll = newStatus;
  }

  // Toggles the is_active property of a specific activity
  toggleActivityStatus(activity: any): void {
    activity.is_active = !activity.is_active;
    this.updateSelectAllStatus();
  }
  // Update the "Select All" checkbox status based on individual checkboxes
  updateSelectAllStatus(): void {
    if (this.activities.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.activities.every(activity => activity.is_active);
    }
  }

  // Method to remove an activity from the list by object reference
  removeAmenity(activity: any): void {
    this.hotelActivities = this.hotelActivities.filter(a => a !== activity);
    if (this.hotelActivities.length === 0) {
      this.selectAll = false;
      this.noActivities = true;
    } else {
      this.noActivities = false;
    }
  }
  subscribeToActivities(): void {
    this.featureService.getFeatures().subscribe((activities:any) => {
      this.activities = activities.data.activities;
      for (var i=0;i<this.activities.length;i++) {
        for (var j=0;j<this.hotelActivities.length;j++) {
            if (this.activities[i].id === this.hotelActivities[j].id) {
                    this.activities[i].is_active = this.hotelActivities[j].is_active;
            }
          }
      }
    });
  }
}
