<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">
    {{ error }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
</div>
<div *ngIf="success" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">
    Themes saved successfully
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="success = false"></button>
</div>
<!-- start page title -->
<app-pagetitle title="Hotel Themes" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<p class="amenities-desc-msg text-muted mt-4 ms-2">Specify the theme that best describes this hotel.</p>
<form class="gx-3 gy-2 align-items-center" (ngSubmit)="onSubmit(hotelThemesForm)" #hotelThemesForm="ngForm">
<div class="card mt-2">
  <div class="card-body">
      <div class="row">
        <div class="col-2">
          <h4 class="card-title">Themes</h4>
        </div>
        <div class="col-10">
          <div class="row">
            <div *ngIf="noThemes" class="no-theme">
              <p class="text-center">No Themes</p>
            </div>
            <!-- Select All Column -->
            <div class="col-12 col-md-2 d-flex justify-content-end" *ngIf="!noThemes">
              <div class="form-check text-room-desc form-room-check" id="categories-pill">
                <input 
                  class="form-check-input cursor-pointer" 
                  type="checkbox" 
                  [id]="'checkAll'"
                  (change)="toggleSelectAll()"
                  [checked]="selectAll"
                  [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                  style="display: none;">
                <label
                  class="form-check-label cursor-pointer categories"
                  [for]="'checkAll'"
                  [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                >
                  Select all
                </label>
              </div>
            </div>

            <!-- Amenities List Column -->
            <div class="col-12 col-md-10">
              <div class="row">
                <div class="col-auto" *ngFor="let theme of themes; let index = index;">
                  <div class="form-check text-room-desc form-room-check" id="categories-pill">
                    <input 
                      class="form-check-input cursor-pointer" 
                      type="checkbox" 
                      [id]="'check' + index"
                      [(checked)]="theme.is_active"
                      (change)="toggleThemeStatus(theme)"
                      [ngClass]="{'active': theme.is_active, 'inactive': !theme.is_active}"
                      style="display: none;">

                    <label
                      class="form-check-label cursor-pointer categories"
                      [ngClass]="{'active': theme.is_active, 'inactive': !theme.is_active}"
                      [for]="'check' + index">
                      {{ theme.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div><!-- end card-body -->
</div><!-- end card -->
<div class="col-12">
  <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer" (click)="saveThemes()" [disabled]="!hotelThemesForm.form.valid">Save changes</button>
</div>
</form>
