import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { NgForm } from '@angular/forms';
import { RoomService } from '../../core/services/room.service';
import { FeatureService } from '../../core/services/feature.service';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrl: './themes.component.scss'
})
export class ThemesComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public hotelThemes: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public i:number = 0;
  themes: any = {};
  selectAll: boolean = false; // Variable to manage the "Select all" checkbox
  noThemes: boolean = false; // Flag to track whether there are no themes
  success:boolean = false;
  error: string = '';

  constructor( private hotelService: HotelService,
    private roomService: RoomService,
    private featureService: FeatureService
  ) { }

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.subscribeToThemes();
    // this.checkAllThemesStatus();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Themes', active: true}
    ];
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        // Clear previous amenities before assigning new ones
        this.hotelThemes = [];

        // Filter hotel features based on feature group and any other conditions
        this.hotel.hotel_features.forEach((hotelfeature: any) => {
          if (hotelfeature.feature_group_id === 4 && hotelfeature.is_active) {
            // Add active features with group_id == 1 to hotelAmenities
            this.hotelThemes.push(hotelfeature);
          }
        });
        // Now, fetch the activities
        this.fetchActivities(); // Fetch the activities only when hotel data is available
      }
    });
  }
  fetchActivities(): void {
    this.featureService.getFeatures().subscribe((themes: any) => {
      this.themes = themes.data.themes;
  
      // Sync the fetched activities with the hotel activities
      this.themes.forEach((theme:any) => {
        const hotelActivity = this.hotelThemes.find(hotelFeature => hotelFeature.id === theme.id);
        if (hotelActivity) {
          theme.is_active = hotelActivity.is_active;
        }
      });
  
      // Check if all activities are selected
      this.updateSelectAllStatus();
    });
  }
  // loadSavedThemes(): void {
  //   const savedThemes = localStorage.getItem('hotelThemes');
  //   if (savedThemes) {
  //     const parsedThemes = JSON.parse(savedThemes);
  //     // Update the hotelThemes array based on the saved data
  //     this.hotelThemes.forEach((theme: any) => {
  //       const savedActivity = parsedThemes.find((saved: any) => saved.id === theme.id);
  //       if (savedActivity) {
  //         theme.is_active = savedActivity.is_active;
  //       }
  //     });
  //   }
  // }

  // Check if all amenities are selected
  checkAllThemesStatus() {
    this.selectAll = this.themes.every((theme:any) => theme.is_active);
  }

  // Save Themes (for button action)
  saveThemes() {
    // Save the selected themes to localStorage
    const selectedThemes = this.hotelThemes.filter(theme => theme.is_active);
    let Result = selectedThemes.map(choice => ({ id: choice.id }));
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const selectedThemes = this.themes.filter((activity: any) => activity.is_active).map((choice: any) => ([choice.id]));
      const result = {
          "features": selectedThemes.map(String)
      };
      this.featureService.saveFeatures(result, this.hotel.id, 4).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          console.log("Success");
        },
        (error) => {
          this.error = 'There was an error saving the themes. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
          console.error("Failed", error);
        }
      );
      console.log('Form submitted with the following themes:', result);
      form.resetForm();
    } else {
      console.log('Form is invalid');
    }
  }

  // Method to toggle the "Select All" checkbox

  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.themes.forEach((theme:any) => theme.is_active = newStatus);
    this.selectAll = newStatus;
  }

  // Toggles the is_active property of a specific theme
  toggleThemeStatus(theme: any): void {
    theme.is_active = !theme.is_active;
    this.updateSelectAllStatus(); // Call to update select-all status, if needed
  }
  // Update the "Select All" checkbox status based on individual checkboxes
  updateSelectAllStatus(): void {
    if (this.themes.length === 0) {
      this.selectAll = false;  // If there are no amenities, uncheck "Select all"
    } else {
      this.selectAll = this.themes.every((theme:any) => theme.is_active);
    }
  }

  // Method to remove an theme from the list by object reference
  removeTheme(theme: any): void {
    // Filter out the theme based on the object reference
    this.hotelThemes = this.hotelThemes.filter(a => a !== theme);
    // Check if there are no themes left
    if (this.hotelThemes.length === 0) {
      this.selectAll = false;  // Uncheck "Select All"
      this.noThemes = true; // Set flag to true when no themes are left
    } else {
      this.noThemes = false; // Reset the flag when there are still themes
    }
  }
  subscribeToThemes():void {
    this.featureService.getFeatures().subscribe((themes:any) => {
      this.themes = themes.data.themes;
      for (var i=0;i<this.themes.length;i++) {
        for (var j=0;j<this.hotelThemes.length;j++) {
            if (this.themes[i].id === this.hotelThemes[j].id) {
                    this.themes[i].is_active = this.hotelThemes[j].is_active;
            }
          }
      }
    });
  }
}

