import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomService } from '../../../core/services/room.service';
import { Room } from '../../../core/interfaces/room';

@Component({
  selector: 'app-room-edit',
  templateUrl: './room-edit.component.html',
  styleUrl: './room-edit.component.scss'
})
export class RoomEditComponent implements OnInit {
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  roomId: any;
  public room: any; // Define your room type properly
  public hotelAmenities: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public i:number = 0;
  selectAll:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roomService: RoomService
  ) {
    this.initializeBreadcrumbs();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Rooms',link: '/rooms' },
      { label: 'Rooms Overview',link: '/rooms' },
      { label: 'Edit room', active: true}
    ];
  }
  ngOnInit(): void {
    this.roomId = this.route.snapshot.paramMap.get('id');
    this.fetchRoomDetails(this.roomId);
    this.checkAllAmenitiesStatus();
  }

  fetchRoomDetails(id: number): void {
    this.roomService.getRoomDetails(id).subscribe((response) => {
      if (response) {
        this.room = response.data;
        console.log(this.room);
      }
    });
  }

  onSubmit() {
    this.router.navigate(['/rooms']);
  }
  // Check if all amenities are selected
  checkAllAmenitiesStatus() {
    this.selectAll = this.hotelAmenities.every(amenity => amenity.is_active);
  }

// Method to toggle the "Select All" checkbox
toggleSelectAll() {
  this.selectAll = !this.selectAll;
  this.hotelAmenities.forEach(amenity => {
    amenity.is_active = this.selectAll; // Set all amenities to the same state
  });
}

  // Update the "Select All" checkbox status based on individual checkboxes
  updateSelectAllStatus(): void {
    this.selectAll = this.hotelAmenities.every(amenity => amenity.is_active);
  }
  removeAmenity(index: number): void {
    // Remove the amenity from the hotelAmenities array by index
    this.hotelAmenities.splice(index, 1);
  }
}
