<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">
    {{ error }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
</div>
<div *ngIf="success" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">
    Activities saved successfully
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="success = false"></button>
</div>

<!-- start page title -->
<app-pagetitle title="Activities in the area" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<p class="amenities-desc-msg text-muted mt-4 ms-2">Specify the activities in and around this hotel. It will be displayed on the hotel page. Any activities missing? Contact us on +41 848 711 717 or hotelsupport&#64;hotelcard.ch</p>
<form class="gx-3 gy-2 align-items-center" (ngSubmit)="onSubmit(hotelActivitiesForm)" #hotelActivitiesForm="ngForm">
<div class="card mt-2">
  <div class="card-body">
      <div class="row">
        <div class="col-2">
          <h4 class="card-title">Activities</h4>
        </div>
        <div class="col-10">
          <div class="row">
            <div *ngIf="noActivities" class="no-activities">
              <p class="text-center">No Activities</p>
            </div>
            <!-- Select All Column -->
            <div class="col-12 col-md-2 d-flex justify-content-end" *ngIf="!noActivities">
              <div class="form-check text-room-desc form-room-check" id="categories-pill">
                <input
                  class="form-check-input cursor-pointer"
                  type="checkbox"
                  [id]="'checkAll'"
                  (change)="toggleSelectAll()"
                  [checked]="selectAll"
                  [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                  style="display: none;">
                <label
                  class="form-check-label cursor-pointer categories categories-pill"
                  [for]="'checkAll'"
                  [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                >
                  Select all
                </label>
              </div>
            </div>

            <!-- Amenities List Column -->
            <div class="col-12 col-md-10">
              <div class="row">
                <div class="col-auto" *ngFor="let activities of activities; let index = index;">
                  <div class="form-check text-room-desc form-room-check" id="categories-pill">
                    <input
                      class="form-check-input cursor-pointer"
                      type="checkbox"
                      [id]="'check' + index"
                      [(checked)]="activities.is_active"
                      (change)="toggleActivityStatus(activities)"
                      [ngClass]="{'active': activities.is_active, 'inactive': !activities.is_active}"
                      style="display: none;">

                    <label
                      class="form-check-label cursor-pointer categories"
                      [ngClass]="{'active': activities.is_active, 'inactive': !activities.is_active}"
                      [for]="'check' + index">
                      {{ activities.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div><!-- end card-body -->
</div><!-- end card -->
<div class="col-12">
  <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer" [disabled]="!hotelActivitiesForm.form.valid">Save changes</button>
</div>
</form>
